// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_termsofuse__hlcIo {
    max-width: 100%;
    margin: 40px auto;
    padding: 0 20px;
  }
  
  h1, h2 {
    margin-top: 0;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  h2 {
    margin-top: 40px;
  }`, "",{"version":3,"sources":["webpack://./src/view/pages/terms-conditions-page/style.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".termsofuse {\n    max-width: 100%;\n    margin: 40px auto;\n    padding: 0 20px;\n  }\n  \n  h1, h2 {\n    margin-top: 0;\n  }\n  \n  p {\n    margin-bottom: 20px;\n  }\n  \n  h2 {\n    margin-top: 40px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsofuse": `style_termsofuse__hlcIo`
};
export default ___CSS_LOADER_EXPORT___;
