// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .loginInput {
    @apply w-[428px] h-10 bg-[#white] mb-3.5 rounded-md px-[1vw] focus:outline-none border-[1px];
    @apply w-[300px] sm:w-[300px] md:w-[350px] lg:w-[428px];
} */

*{
    /* cursor: default */
}

.style_loginInputEmail__Ojw3F{

    margin-bottom: 0.875rem;

    height: 2.5rem;

    width: 100%;

    border-bottom-width: 1px;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    padding-left: 1vw;

    padding-right: 1vw
}

.style_loginInputEmail__Ojw3F:focus{

    outline: 2px solid transparent;

    outline-offset: 2px
}

@media (min-width: 640px){

    .style_loginInputEmail__Ojw3F{

        border-radius: 0.375rem;

        border-width: 1px
    }
}
.style_loginInputPassword__6aoW7{

    height: 2.5rem;

    width: 60%;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    padding-left: 1vw;

    padding-right: 1vw
}
.style_loginInputPassword__6aoW7:focus{

    outline: 2px solid transparent;

    outline-offset: 2px
}
@media (min-width: 640px){

    .style_loginInputPassword__6aoW7{

        margin-bottom: 0.875rem;

        width: 100%;

        border-radius: 0.375rem;

        border-width: 1px;

        border-bottom-width: 1px
    }
}
`, "",{"version":3,"sources":["webpack://./src/view/pages/signIn-page/style.module.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,oBAAoB;AACxB;;AAGI;;IAAA,uBAAgG;;IAAhG,cAAgG;;IAAhG,WAAgG;;IAAhG,wBAAgG;;IAAhG,kBAAgG;;IAAhG,yDAAgG;;IAAhG,iBAAgG;;IAAhG;AAAgG;;AAAhG;;IAAA,8BAAgG;;IAAhG;AAAgG;;AAAhG;;IAAA;;QAAA,uBAAgG;;QAAhG;IAAgG;AAAA;AAGhG;;IAAA,cAAiH;;IAAjH,UAAiH;;IAAjH,kBAAiH;;IAAjH,yDAAiH;;IAAjH,iBAAiH;;IAAjH;AAAiH;AAAjH;;IAAA,8BAAiH;;IAAjH;AAAiH;AAAjH;;IAAA;;QAAA,uBAAiH;;QAAjH,WAAiH;;QAAjH,uBAAiH;;QAAjH,iBAAiH;;QAAjH;IAAiH;AAAA","sourcesContent":["/* .loginInput {\n    @apply w-[428px] h-10 bg-[#white] mb-3.5 rounded-md px-[1vw] focus:outline-none border-[1px];\n    @apply w-[300px] sm:w-[300px] md:w-[350px] lg:w-[428px];\n} */\n\n*{\n    /* cursor: default */\n}\n\n.loginInputEmail{\n    @apply  w-full h-10 bg-white mb-3.5 sm:rounded-md sm:border border-b  focus:outline-none px-[1vw]\n}\n.loginInputPassword{\n    @apply  sm:w-full w-[60%] h-10 bg-white sm:mb-3.5 sm:rounded-md sm:border sm:border-b  focus:outline-none px-[1vw]\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginInputEmail": `style_loginInputEmail__Ojw3F`,
	"loginInputPassword": `style_loginInputPassword__6aoW7`
};
export default ___CSS_LOADER_EXPORT___;
