// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formInput__Lex-a {

    width: 100%;

    resize: none;

    border-radius: 0.5rem;

    border-width: 1px;

    border-color: rgb(107 114 128 / var(--tw-border-opacity));

    --tw-border-opacity: 0.3;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    padding: 0.5rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}

.style_formInput__Lex-a:focus {

    outline: 2px solid transparent;

    outline-offset: 2px
}

.style_formInput__Lex-a:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

@media (min-width: 640px) {

    .style_formInput__Lex-a {

        max-height: 5rem
    }
}

.style_formImageInputBtnWrapper__mUqEy {

    position: relative;

    display: inline-block;

    height: 160px;

    width: 160px;

    overflow: hidden;

    border-radius: 0.25rem;

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity));

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.style_formImageInputBtnLabel__S2LO\\+ {

    cursor: pointer
}`, "",{"version":3,"sources":["webpack://./src/view/pages/enrolment-form-page/style.module.css"],"names":[],"mappings":"AACI;;IAAA,WAAoK;;IAApK,YAAoK;;IAApK,qBAAoK;;IAApK,iBAAoK;;IAApK,yDAAoK;;IAApK,wBAAoK;;IAApK,kBAAoK;;IAApK,yDAAoK;;IAApK,eAAoK;;IAApK,mBAAoK;;IAApK,oBAAoK;;IAApK,oBAAoK;;IAApK;AAAoK;;AAApK;;IAAA,8BAAoK;;IAApK;AAAoK;;AAApK;;IAAA,kBAAoK;;IAApK;AAAoK;;AAApK;;IAAA;;QAAA;IAAoK;AAAA;;AAIpK;;IAAA,kBAA8F;;IAA9F,qBAA8F;;IAA9F,aAA8F;;IAA9F,YAA8F;;IAA9F,gBAA8F;;IAA9F,sBAA8F;;IAA9F,kBAA8F;;IAA9F,yDAA8F;;IAA9F,6EAA8F;;IAA9F,iGAA8F;;IAA9F;AAA8F;;AAI9F;;IAAA;AAAqB","sourcesContent":[".formInput{\n    @apply text-sm text-gray-500 resize-none w-full border border-gray-500 border-opacity-30 bg-white disabled:bg-slate-100 sm:max-h-20 p-2 rounded-lg focus:outline-none\n}\n\n.formImageInputBtnWrapper {\n    @apply relative inline-block overflow-hidden h-[160px] w-[160px] bg-gray-200 rounded shadow-md;\n}\n\n.formImageInputBtnLabel {\n    @apply cursor-pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInput": `style_formInput__Lex-a`,
	"formImageInputBtnWrapper": `style_formImageInputBtnWrapper__mUqEy`,
	"formImageInputBtnLabel": `style_formImageInputBtnLabel__S2LO+`
};
export default ___CSS_LOADER_EXPORT___;
