
import { AcademyProfileDataWrapper } from "../models/academy-profile/academy-profile-data-wrapper";
import { AcademyProfileListWrapper } from "../models/academy-profile/academy-profile-list-wrapper";
import { AcademyReviewPostData } from "../models/academy-profile/academy-review-post-data";
import { EnquiryListWrapper } from "../models/enquiry/enquiry-list-wrapper";
import { EnquiryPostData } from "../models/enquiry/enquiry-post-data";
import { EnquiryPostDataWrapper } from "../models/enquiry/enquiry-post-data-wrapper";
import { EnquiryWrapper } from "../models/enquiry/enquiry-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class AcademyProfileService{
    private static _instance: AcademyProfileService;
    private constructor() { }
    
    public static get instance() {
        return this._instance ?? (this._instance = new AcademyProfileService());
    }

    public getAcademyProfileList = async () => {
        return invokeApi<null, AcademyProfileListWrapper>({
            route: `academy-profiles?filters[isLive]=true&populate[0]=academy.images&populate[2]=academy.selectedDisciplines`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public getGlobalSearchAcademiesList = async (term: string) => {
        return invokeApi<null, AcademyProfileListWrapper>({
            route: `academy-profiles?filters[isLive]=true&populate[0]=academy.logo&filters[$or][0][academy][name][$containsi]=${term}&filters[$or][1][publicName][$containsi]=${term}&filters[$or][2][academy][selectedDisciplines][name][$contains]=${term}&filters[$or][3][academy][address][$contains]=${term}`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public getAcademyProfile = async (id: number) => {
        return invokeApi<null, AcademyProfileDataWrapper>({
            route: `academy-profiles/${id}?populate[0]=academy.images&populate[1]=reviewRatings.givenBy&populate[2]=academy.selectedDisciplines&populate[3]=academy.users`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public academyProfileLike = async (id: number) => {
        return invokeApi<null, AcademyProfileDataWrapper>({
            route: `academy-profile/like/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public undoacademyProfileLike = async (id: number) => {
        return invokeApi<null, AcademyProfileDataWrapper>({
            route: `academy-profile/like/undo/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public academyProfileDislike = async (id: number) => {
        return invokeApi<null, AcademyProfileDataWrapper>({
            route: `academy-profile/dislike/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public undoacademyProfileDislike = async (id: number) => {
        return invokeApi<null, AcademyProfileDataWrapper>({
            route: `academy-profile/dislike/undo/${id}`,
            method: 'GET',
            privateRoute: false,
        });
    };

    public postAcademyReview = async (id: number, data: AcademyReviewPostData) => {
        return invokeApi<AcademyReviewPostData, AcademyProfileDataWrapper>({
            route: `academy-profile/review/add/${id}`,
            method: 'POST',
            privateRoute: true,
            data: data
        });
    };

    public bookClassDemo = async (data: EnquiryPostData) => {
        return invokeApi<EnquiryPostDataWrapper, EnquiryWrapper>({
            route: `enquiries`,
            method: 'POST',
            privateRoute: true,
            data: {data}
        });
    };

    public getExistingDemoBooking = async(userId: number,academyId: number) => {
        return invokeApi<null, EnquiryListWrapper>({
            route: `enquiries?populate=*&filters[addedBy][id]=${userId}&filters[demoDateAndTime][$gt]=${new Date().toISOString()}&filters[academies][id]=${academyId}`,
            method: 'GET',
            privateRoute: true,
        });
    }
}