import { Helmet } from "react-helmet-async";
import Navbar from "../../components/landing-page-items/navbar";
import Footer from "../../components/landing-page-items/footer";
import AcademyProfileListViewModel from "../../view-models/academy-profile-list-viewmodel";
import Skeleton from "react-loading-skeleton";

const AcademiesListPage = () => {

    const { 
        isLoading,
        academyProfiles, 
        handleOpenAcademyProfile 
    } = AcademyProfileListViewModel();

    return (
        <div className='h-screen flex flex-col'>
            <Helmet>
                <title>SpArts Academies | Discover Sports & Arts Programs for Students</title>
                <meta name="description" content={`Find top-rated sports and arts academies with SpArts. Explore programs designed to support student growth through structured training, skill-building exercises, and SpArts AI for personalized development.`} />
            </Helmet>
            <Navbar />
            {isLoading ? (
                <div className='grid grid-cols-1 gap-4 p-6 md:px-16 mt-[88px]'>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <div
                            key={index}
                            className='p-2 rounded-md bg-slate-200 shadow-md shadow-slate-200 flex flex-col md:flex-row items-center md:gap-4 gap-1'
                        >
                            <div className="w-full md:w-[440px] h-48 md:h-64 rounded-lg bg-slate-50 animate-pulse"></div>
                            <div className='rounded-lg p-4 flex flex-col gap-2'>
                                <Skeleton height={30} width={200} />
                                <Skeleton height={20} width={150} />
                                <Skeleton height={20} width={250} />
                                <Skeleton className='flex items-center' height={30} width={80} />
                    
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap md:py-2 mt-2 text-sm">
                                    {Array.from({ length: 3 }).map((_, idx) => (
                                    <div key={idx} className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                        <Skeleton circle height={24} width={24} />
                                        <Skeleton width={80} height={20} />
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className='grid grid-cols-1 gap-4 p-6 md:px-16 mt-[88px]'>
                    {academyProfiles.map((academy) => (
                        <div 
                            key={academy?.id}
                            className='p-2 cursor-pointer rounded-md bg-slate-200 shadow-md shadow-slate-200 flex flex-col md:flex-row md:gap-4 gap-1'
                            onClick={() => handleOpenAcademyProfile(academy?.id)}
                        >
                            <img 
                                src={academy?.attributes?.academy?.data?.attributes?.images?.data ? academy?.attributes?.academy?.data?.attributes?.images?.data[0]?.attributes?.url : ''}
                                className='w-full md:w-[440px] h-48 md:h-64 object-cover rounded-lg cursor-pointer'
                            />
                            <div className='rounded-lg p-4 cursor-pointer'>
                                <div className='flex flex-col'>
                                    <div className='text-xl md:text-3xl font-semibold'>{academy?.attributes?.publicName}</div>
                                    <div className='text-gray-500 font-semibold text-sm line-clamp-3'>{academy?.attributes?.academy?.data?.attributes?.address}</div>
                                </div>
                                <div className="flex items-center mt-2">
                                    <div className='text-gray-500 font-semibold text-sm bg-green-400 p-1 px-2 rounded-lg flex gap-2 items-center'>
                                        {academy?.attributes?.academy?.data?.attributes?.rating ? academy?.attributes?.academy?.data?.attributes?.rating?.toFixed(1) : 'No rating '}
                                        <img src='/assets/images/star-gray.png' className="h-4 w-4"/>
                                    </div>
                                </div>
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap md:py-2 mt-2 text-sm">
                                    {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Certified coach') && (
                                        <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                            <img src="/assets/images/coach.png" className="h-6 w-6" />
                                            <div>Certified Coach</div>
                                        </div>
                                    )}
                                    {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Parking') && (
                                        <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                            <img src="/assets/images/parking.png" className="h-6 w-6" />
                                            <div>Parking</div>
                                        </div>
                                    )}
                                    {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Waiting-room') && (
                                        <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                            <img src="/assets/images/lounge.png" className="h-6 w-6" />
                                            <div>Waiting Room</div>
                                        </div>
                                    )}
                                    {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Restroom') && (
                                        <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                            <img src="/assets/images/toilet.png" className="h-6 w-6" />
                                            <div>Restroom</div>
                                        </div>
                                    )}
                                    {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('CCTV') && (
                                        <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                            <img src="/assets/images/cctv.png" className="h-6 w-6" />
                                            <div>CCTV</div>
                                        </div>
                                    )}
                                    {academy?.attributes?.academy?.data?.attributes?.ammenities?.split(',').includes('Drinking water') && (
                                        <div className="flex items-center gap-2 rounded-lg bg-slate-100 px-2 py-1">
                                            <img src="/assets/images/water.png" className="h-6 w-6" />
                                            <div>Drinking water</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <Footer />
        </div>
    )
}

export default AcademiesListPage;